<!--/**
* Author: ganqz
* Date: 2021-11-27 22:25
* Desc: AnswerCard
*/
-->
<template>
  <div class="extend answer-card df-col">
    <div class="title tc pt15 plr15">答题卡</div>
    <van-divider></van-divider>
    <div class="list flex-1 cf">
      <div class="item" v-for="(item, index) in list" :key="index" @click="handleClick(index, item)" :class="[isResult?
              (item.right?'right':'error'):'']">
        <span class="card-tag" :class="{ active: item.answers.length }">{{ index + 1 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnswerCard',
  components: {},
  props: {
    isResult: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    handleClick(index, item) {
      this.$emit('item-click', index, item);
    }
  }
};
</script>
<style lang="scss" scoped>
.list {
  overflow-x: hidden;
  overflow-y: auto;
  .item {
    float: left;
    width: 20%;
    text-align: center;
    &.error{
        .card-tag{
            background:$--color-danger;
            border-color: $--color-danger;
             color: #fff;
            &.active{
                 background:$--color-danger;
            border-color: $--color-danger;
            }
        }
    }
    &.right{
        .card-tag{
            background:$--color-success;
            border-color: $--color-success;
             color: #fff;
            &.active{
                 background:$--color-success;
                border-color: $--color-success;
            }
        }
    }
    .card-tag {
      width: 0.9rem;
      height: 0.9rem;
      line-height: 0.9rem;
      border-radius: 50%;
      border: 1px solid #888;
      display: inline-block;
      margin-bottom: 0.3rem;
      &.active {
        background: $--color-primary;
        border-color: $--color-primary;
        color: #fff;
      }
    }
  }
}
</style>

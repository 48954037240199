<!--/**
* Author: ganqz
* Date: 2021-11-27 13:40
* Desc: Home
*/
-->
<template>
  <zmAppPage
    class="bg-white"
    :show-footer="true"
    :showHeaderLeft="true"
    :show-header-right="true"
    :class="[{ 'is-result': isResult }]"
  >
    <div slot="headerTitle">
      <!-- <div class="flex flex-center"> -->
      <van-button size="small" class="vm" icon="arrow-left" plain type="primary" @click="onChange(0)" />
      <span class="vm mlr10">{{ `${!list.length ? 0 : index + 1}/${list.length}` }}</span>
      <van-button class="vm" size="small" icon="arrow" plain type="primary" @click="onChange(1)" />
      <!-- </div> -->
    </div>
    <span slot="headerRight" v-if="!isResult">{{ time }}</span>
    <div class="plr15 ptb15">
      <div class="item">
        <div class="item-title">
          <span class="item-title__tag">{{ tagName }}</span>
          <span>{{ content && content.title }}</span>
          <!-- <span class="fs12 fc9">({{ (currentItem.questionPoint/100).toFixed(1) || 0 }})分</span> -->
        </div>
        <van-divider></van-divider>
        <div class="plr10" v-if="currentItem.questionTypeId !== 3">
          <div
            class="answer-item flex"
            v-for="(value, key) in content && content.choiceList"
            :key="key"
            @click="onAnswer(key)"
          >
            <span
              class="answer-tag"
              v-if="isResult"
              :class="[
                { active: currentItemAnswer.includes(key) && currentItem.answer.indexOf(key) > -1 },
                { answer: currentItem.answer.indexOf(key) > -1 && !currentItemAnswer.includes(key) },
                { error: currentItemAnswer.includes(key) && currentItem.answer.indexOf(key) === -1 }
              ]"
              >{{ currentItemAnswer.includes(key) && currentItem.answer.indexOf(key) === -1 ? 'X' : key }}</span
            >
            <span class="answer-tag" :class="[{ active: currentItemAnswer.includes(key) }]" v-else>{{ key }}</span>
            <div class="flex-1 value ml10">{{ `${value}` }}</div>
          </div>
        </div>
        <div class="prl10 df-center" v-else>
          <span
            class="answer-tag tf"
            v-if="isResult"
            :class="[
              { active: currentItemAnswer.includes('T') && currentItem.answer.indexOf('T') > -1 },
              { answer: currentItem.answer.indexOf('T') > -1 && !currentItemAnswer.includes('T') },
              { error: currentItemAnswer.includes('T') && currentItem.answer.indexOf('T') === -1 }
            ]"
          >
            <b class="flex-1">对</b>
          </span>
          <van-button
            :plain="!currentItemAnswer.includes('T')"
            :type="currentItemAnswer.includes('T') ? 'info' : 'info'"
            v-else
            class="flex-1"
            @click="onAnswer('T')"
            >对</van-button
          >
          <span
            class="answer-tag tf"
            v-if="isResult"
            :class="[
              { active: currentItemAnswer.includes('F') && currentItem.answer.indexOf('F') > -1 },
              { answer: currentItem.answer.indexOf('F') > -1 && !currentItemAnswer.includes('F') },
              { error: currentItemAnswer.includes('F') && currentItem.answer.indexOf('F') === -1 }
            ]"
          >
            <b class="flex-1">错</b>
          </span>
          <van-button
            :plain="!currentItemAnswer.includes('F')"
            :type="currentItemAnswer.includes('F') ? 'info' : 'info'"
            v-else
            class="flex-1"
            @click="onAnswer('F')"
            >错</van-button
          >
        </div>
      </div>
    </div>
    <div class="bg-white pd10" slot="footer" v-if="this.list.length">
      <div v-if="isResult" class="df-center pb10 text-primary fs18">
        <span class="mr10">正确答案为:</span>
        <span v-if="currentItem.questionTypeId === 3">{{ currentItem.answer === 'T' ? '对' : '错' }}</span>
        <span v-else>{{ currentItem.answer }}</span>
      </div>
      <div class="df-center">
        <zm-icon icon-class="answer" class="answer-icon fs30 mr10" @click="showAnswer = true"></zm-icon>
        <van-button type="info" class="flex-1" @click="onChange(0)" v-if="showPrev">上一题</van-button>
        <van-button type="info" class="flex-1" @click="onChange(1)" v-if="showNext">下一题</van-button>
        <van-button type="primary" class="flex-1" @click="onSubmit" v-if="!showNext && !isResult">提交</van-button>
      </div>
    </div>
    <van-popup
      v-model="showAnswer"
      position="bottom"
      closeable
      :style="{ width: '100%', height: '80%', position: 'fixed' }"
      round
    >
      <card :list="list" @item-click="itemClick" :is-result="!!isResult"></card>
    </van-popup>
  </zmAppPage>
</template>

<script>
import card from '@/pages/home/card';
import { PATH_RESULT_INFO } from '@/router';
import { mapActions } from 'vuex';
import { getSeconds } from './getSecond';
import * as auth from '@/utils/auth';
import { mapGetters } from 'vuex';
import { appGetToken } from '@/utils';
export default {
  name: 'Home',
  components: { card },
  data() {
    return {
      list: [],
      showAnswer: false,
      index: 0,
      duration: 0,
      examId: '',
      timer: null,
      surplus: 0
    };
  },
  computed: {
    ...mapGetters(['token', 'userInfo']),
    query() {
      return this.$route.query;
    },
    isResult() {
      return this.query.examHistoryId;
    },
    time() {
      return getSeconds(this.surplus);
    },
    showNext() {
      return this.index >= 0 && this.index !== this.list.length - 1 && (this.surplus || this.isResult);
    },
    showPrev() {
      return this.list.length > 1 && this.index !== 0 && (this.surplus || this.isResult);
    },
    currentItem() {
      const item = this.list[this.index];
      return item || {};
    },
    content() {
      return this.currentItem.content;
    },
    currentItemAnswer() {
      return this.currentItem.answers || [];
    },
    tagName() {
      const map = ['单选题', '多选题', '判断题'];
      const type = this.currentItem && this.currentItem.questionTypeId;
      return (this.currentItem && map[type - 1]) || '--';
    }
  },
  watch: {},
  created() {
    if (this.isResult) {
      this.examResult();
    } else {
      this.startExam();
    }
  },
  destroyed() {
    this.clearTimer();
  },
  methods: {
    ...mapActions('app', ['setResultInfo']),
    async examResult() {
      const res = await this.$apis.examsResult({ examHistoryId: this.query.examHistoryId });
      const htmlStr = JSON.parse(res.htmlStr);
      const _res = htmlStr.map(item => {
        return {
          ...item,
          answers: item.userAnswer.split(''),
          content: JSON.parse(item.content)
        };
      });
      this.list = _res;
    },
    async startExam() {
      const examId = auth.loc.get('examId', '');
      const res = await this.$apis.examsStart({ ...this.userInfo, examId });
      const htmlStr = JSON.parse(res.htmlStr);
      const _res = htmlStr.map(item => {
        return {
          ...item,
          answers: [],
          content: JSON.parse(item.content)
        };
      });
      this.list = _res;
      this.duration = res.duration;
      this.examId = res.examId;
      this.timeCount();
    },
    timeCount() {
      let vm = this;
      this.surplus = this.duration;
      this.$msg.showMsgSuccess(`答题开始!`);
      this.timer = setInterval(() => {
        if (vm.surplus > 0) {
          vm.surplus = vm.surplus - 1;
        } else {
          vm.clearTimer();
          this.$msg
            .alert({
              title: '友情提示',
              message: `答题时间已结束,请停止作答.是否确认提交!`
            })
            .then(() => {
              this.submitFetch();
            })
            .catch(() => {
              this.showAnswer = true;
            });
        }
      }, 1000);
    },
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    itemClick(index, item) {
      this.index = index;
      this.showAnswer = false;
    },
    onChange(type) {
      if (type) {
        if (this.index < this.list.length - 1) this.index += 1;
        if (this.index === this.list.length - 1) {
          this.$msg.showMsgFail('最后一题了');
        }
      } else {
        if (this.index) this.index -= 1;
        if (this.index === 0) {
          this.$msg.showMsgFail('第一题了');
        }
      }
    },
    onAnswer(key) {
      if (this.isResult) return;
      let item = this.currentItem;
      let answers = this.currentItem.answers;
      console.log(key, answers, item);
      if (item.questionTypeId === 1 || item.questionTypeId === 3) {
        // 选择过了
        if (answers.includes(key) && answers.length) {
          answers = [];
        } else {
          answers = [key];
        }
      }
      if (item.questionTypeId === 2) {
        if (answers.includes(key)) {
          const _ki = answers.indexOf(key);
          answers.splice(_ki, 1);
        } else {
          answers.push(key);
        }
      }
      this.$set(this.list, this.index, { ...item, answers });
    },
    onSubmit() {
      const res = this.list.some(item => {
        return !item.answers.length;
      });
      console.log(res);
      if (res) {
        this.$msg
          .confirm({
            title: '友情提示',
            message: '您还有题目未作答,是否确认提交!'
          })
          .then(() => {
            this.submitFetch();
          })
          .catch(() => {
            this.showAnswer = true;
          });
      } else {
        this.submitFetch();
      }
    },
    async submitFetch() {
      const answerSheetItems = this.list.map(item => {
        return {
          answer: item.answers.join(''),
          questionTypeId: item.questionTypeId,
          questionId: item.questionId
        };
      });

      const params = {
        answerSheetItems,
        duration: this.duration - this.surplus,
        examId: this.examId,
        answerUserId: appGetToken(),
        type: +this.query.type
      };
      const res = await this.$apis.examsAnswersSubmit(params);
      this.clearTimer();
      this.setResultInfo(res);
      this.$router.push({ path: PATH_RESULT_INFO });
    }
  }
};
</script>
<style lang="scss" scoped>
.vm {
  display: inline-block;
  vertical-align: middle;
}
.item {
  &-title {
    font-size: 0.32rem;
    &__tag {
      display: inline-block;
      margin-right: 10px;
      background: lighten($--color-primary, 20%);
      padding: 0 0.1rem;
      border-radius: 0.2rem;
      color: #fff;
      font-size: 0.24rem;
    }
  }
  .answer {
    &-tag {
      width: 0.5rem;
      height: 0.5rem;
      text-align: center;
      line-height: 0.5rem;
      border: 1px solid #888;
      border-radius: 0.1rem;
      color: #888;
      &.tf {
        height: 0.7rem;
        line-height: 0.7rem;
        flex: 1;
        padding: 0 10px;
        display: flex;
        align-items: center;
        &.error {
          border-radius: 0;
          &::before {
            content: 'x';
            display: inline-block;
            font-size: 24px;
            width: 0.5rem;
            border-radius: 50%;
            background: lighten($--color-danger, 20%);
            height: 0.5rem;
            text-align: center;
            line-height: 0.45rem;
            color: #fff;
          }
        }
      }
      & + .answer-tag {
        margin-left: 10px;
      }
      &.active {
        background: $--color-primary;
        border-color: $--color-primary;
        color: #fff;
      }
      &.answer {
        border-color: $--color-success;
        background: $--color-success;
        color: #fff;
      }
      &.error {
        border-color: $--color-danger;
        background: $--color-danger;
        color: #fff;
        position: relative;
        border-radius: 50%;
      }
    }
    &-item {
      padding-bottom: 0.3rem;
      margin-bottom: 0.2rem;
      .value {
        line-height: 1.6;
        margin-top: 0.03rem;
        // font-size:.30rem;
        color: #666;
      }
    }
  }
}
// .footer{
.answer-icon {
  color: #d76873;
}
// }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('zmAppPage',{staticClass:"bg-white",class:[{ 'is-result': _vm.isResult }],attrs:{"show-footer":true,"showHeaderLeft":true,"show-header-right":true}},[_c('div',{attrs:{"slot":"headerTitle"},slot:"headerTitle"},[_c('van-button',{staticClass:"vm",attrs:{"size":"small","icon":"arrow-left","plain":"","type":"primary"},on:{"click":function($event){return _vm.onChange(0)}}}),_c('span',{staticClass:"vm mlr10"},[_vm._v(_vm._s(((!_vm.list.length ? 0 : _vm.index + 1) + "/" + (_vm.list.length))))]),_c('van-button',{staticClass:"vm",attrs:{"size":"small","icon":"arrow","plain":"","type":"primary"},on:{"click":function($event){return _vm.onChange(1)}}})],1),(!_vm.isResult)?_c('span',{attrs:{"slot":"headerRight"},slot:"headerRight"},[_vm._v(_vm._s(_vm.time))]):_vm._e(),_c('div',{staticClass:"plr15 ptb15"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-title"},[_c('span',{staticClass:"item-title__tag"},[_vm._v(_vm._s(_vm.tagName))]),_c('span',[_vm._v(_vm._s(_vm.content && _vm.content.title))])]),_c('van-divider'),(_vm.currentItem.questionTypeId !== 3)?_c('div',{staticClass:"plr10"},_vm._l((_vm.content && _vm.content.choiceList),function(value,key){return _c('div',{key:key,staticClass:"answer-item flex",on:{"click":function($event){return _vm.onAnswer(key)}}},[(_vm.isResult)?_c('span',{staticClass:"answer-tag",class:[
              { active: _vm.currentItemAnswer.includes(key) && _vm.currentItem.answer.indexOf(key) > -1 },
              { answer: _vm.currentItem.answer.indexOf(key) > -1 && !_vm.currentItemAnswer.includes(key) },
              { error: _vm.currentItemAnswer.includes(key) && _vm.currentItem.answer.indexOf(key) === -1 }
            ]},[_vm._v(_vm._s(_vm.currentItemAnswer.includes(key) && _vm.currentItem.answer.indexOf(key) === -1 ? 'X' : key))]):_c('span',{staticClass:"answer-tag",class:[{ active: _vm.currentItemAnswer.includes(key) }]},[_vm._v(_vm._s(key))]),_c('div',{staticClass:"flex-1 value ml10"},[_vm._v(_vm._s(("" + value)))])])}),0):_c('div',{staticClass:"prl10 df-center"},[(_vm.isResult)?_c('span',{staticClass:"answer-tag tf",class:[
            { active: _vm.currentItemAnswer.includes('T') && _vm.currentItem.answer.indexOf('T') > -1 },
            { answer: _vm.currentItem.answer.indexOf('T') > -1 && !_vm.currentItemAnswer.includes('T') },
            { error: _vm.currentItemAnswer.includes('T') && _vm.currentItem.answer.indexOf('T') === -1 }
          ]},[_c('b',{staticClass:"flex-1"},[_vm._v("对")])]):_c('van-button',{staticClass:"flex-1",attrs:{"plain":!_vm.currentItemAnswer.includes('T'),"type":_vm.currentItemAnswer.includes('T') ? 'info' : 'info'},on:{"click":function($event){return _vm.onAnswer('T')}}},[_vm._v("对")]),(_vm.isResult)?_c('span',{staticClass:"answer-tag tf",class:[
            { active: _vm.currentItemAnswer.includes('F') && _vm.currentItem.answer.indexOf('F') > -1 },
            { answer: _vm.currentItem.answer.indexOf('F') > -1 && !_vm.currentItemAnswer.includes('F') },
            { error: _vm.currentItemAnswer.includes('F') && _vm.currentItem.answer.indexOf('F') === -1 }
          ]},[_c('b',{staticClass:"flex-1"},[_vm._v("错")])]):_c('van-button',{staticClass:"flex-1",attrs:{"plain":!_vm.currentItemAnswer.includes('F'),"type":_vm.currentItemAnswer.includes('F') ? 'info' : 'info'},on:{"click":function($event){return _vm.onAnswer('F')}}},[_vm._v("错")])],1)],1)]),(this.list.length)?_c('div',{staticClass:"bg-white pd10",attrs:{"slot":"footer"},slot:"footer"},[(_vm.isResult)?_c('div',{staticClass:"df-center pb10 text-primary fs18"},[_c('span',{staticClass:"mr10"},[_vm._v("正确答案为:")]),(_vm.currentItem.questionTypeId === 3)?_c('span',[_vm._v(_vm._s(_vm.currentItem.answer === 'T' ? '对' : '错'))]):_c('span',[_vm._v(_vm._s(_vm.currentItem.answer))])]):_vm._e(),_c('div',{staticClass:"df-center"},[_c('zm-icon',{staticClass:"answer-icon fs30 mr10",attrs:{"icon-class":"answer"},on:{"click":function($event){_vm.showAnswer = true}}}),(_vm.showPrev)?_c('van-button',{staticClass:"flex-1",attrs:{"type":"info"},on:{"click":function($event){return _vm.onChange(0)}}},[_vm._v("上一题")]):_vm._e(),(_vm.showNext)?_c('van-button',{staticClass:"flex-1",attrs:{"type":"info"},on:{"click":function($event){return _vm.onChange(1)}}},[_vm._v("下一题")]):_vm._e(),(!_vm.showNext && !_vm.isResult)?_c('van-button',{staticClass:"flex-1",attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("提交")]):_vm._e()],1)]):_vm._e(),_c('van-popup',{style:({ width: '100%', height: '80%', position: 'fixed' }),attrs:{"position":"bottom","closeable":"","round":""},model:{value:(_vm.showAnswer),callback:function ($$v) {_vm.showAnswer=$$v},expression:"showAnswer"}},[_c('card',{attrs:{"list":_vm.list,"is-result":!!_vm.isResult},on:{"item-click":_vm.itemClick}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
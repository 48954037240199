export function getSeconds(s) {
  var sTime = parseInt(s); // 秒
  var mTime = 0; // 分
  var hTime = 0; // 时
  if (sTime > 60) {
    mTime = parseInt(sTime / 60);
    sTime = parseInt(sTime % 60);
    if (mTime > 60) {
      hTime = parseInt(mTime / 60);
      mTime = parseInt(mTime % 60);
    }
  }
  var result = '';
  if (sTime >= 0 && sTime < 10) {
    result = '0' + parseInt(sTime) + '';
  } else {
    result = '' + parseInt(sTime) + '';
  }
  if (mTime >= 0 && mTime < 10) {
    result = '0' + parseInt(mTime) + ':' + result;
  } else {
    result = '' + parseInt(mTime) + ':' + result;
  }
  if (hTime > 0 && hTime < 10) {
    result = '0' + parseInt(hTime) + ':' + result;
  } else {
    if (hTime !== 0) {
      result = '' + parseInt(hTime) + ':' + result;
    }
  }
  return result;
}
export function formatSeconds(s) {
  var sTime = parseInt(s); // 秒
  var mTime = 0; // 分
  var hTime = 0; // 时
  if (sTime > 60) {
    mTime = parseInt(sTime / 60);
    sTime = parseInt(sTime % 60);
  }
  var result = '';
  if (sTime >= 0 && sTime < 10) {
    result = '0' + parseInt(sTime) + '';
  } else {
    result = '' + parseInt(sTime) + '';
  }
  if (mTime >= 0 && mTime < 10) {
    result = '0' + parseInt(mTime) + ':' + result;
  } else {
    result = '' + parseInt(mTime) + ':' + result;
  }
  return result;
}
export function getSecondsCn(s) {
  var sTime = parseInt(s); // 秒
  var mTime = 0; // 分
  var hTime = 0; // 时
  if (sTime > 60) {
    mTime = parseInt(sTime / 60);
    sTime = parseInt(sTime % 60);
    if (mTime > 60) {
      hTime = parseInt(mTime / 60);
      mTime = parseInt(mTime % 60);
    }
  }
  var result = '';
  if (sTime >= 0 && sTime < 10) {
    result = '0' + parseInt(sTime) + '秒';
  } else {
    result = '' + parseInt(sTime) + '秒';
  }
  if (mTime >= 0 && mTime < 10) {
    result = '0' + parseInt(mTime) + '分' + result;
  } else {
    result = '' + parseInt(mTime) + '分' + result;
  }
  if (hTime > 0 && hTime < 10) {
    result = '0' + parseInt(hTime) + '时' + result;
  } else {
    if (hTime !== 0) {
      result = '' + parseInt(hTime) + '时' + result;
    }
  }
  return result;
}